@import "../../assets/css/color";

.bar {
  height: 0.75em;
  border-radius: 20px;
  background-color: $second-color-bg;
  width: 100px;
  .progress {
    border-radius: 20px;
    height: 0.75em;
    background-color: $second-color;
  }
}

.bar-medium {
  background-color: $first-color-bg;
  .progress {
    background-color: $first-color;
  }
}

.bar-high {
  background-color: $third-color-bg;
  .progress {
    background-color: $third-color;
  }
}

.bar-very-high {
  background-color: $forth-color-bg;
  .progress {
    background-color: $forth-color;
  }
}

.container {
  display: flex;
  align-items: center;
  .number {
    color: $text-secondary-color;
    margin-left: 0.5em;
  }
}
