@import "../../assets/css/color";
@import "../../assets/css/var";

.container {
  overflow: hidden;
  .switch {
    svg {
      font-size: 1.2em;
      vertical-align: middle;
      margin: 0 0.25em;
    }
  }
}
