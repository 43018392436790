@import "../../assets/css/color";

.icon {
  font-size: 4em;
  line-height: 1;
  padding-top: 1.2em;
}

.icon-success {
  color: $second-color;
}

.icon-error {
  color: $forth-color;
}
