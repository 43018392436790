@import "../../assets/css/color";

.status {
  text-transform: capitalize;
  border-radius: 30px;
  padding: 0.5em;
  font-size: 0.85em;
  font-weight: 600;
  text-align: center;
  width: 10em;
  border: 1px solid transparent;
}
.status-scheduled {
  background-color: $first-color-bg;
  color: $first-color;
}

.status-confirmed {
  background-color: $third-color-bg;
  color: $third-color;
}

.status-checked-in {
  background-color: $third-color;
  color: #fff;
}

.status-serving {
  background-color: $second-color;
  color: #fff;
}

.status-completed {
  background-color: $second-color-bg;
  color: $second-color;
}
.status-uncompleted {
  background-color: $forth-color-bg;
  color: $forth-color;
}
.status-ended {
  background-color: $light-color;
  color: $text-secondary-color;
}
.status-not-attend {
  background-color: $fifth-color-bg;
  color: $fifth-color;
}

.status-past {
  background-color: $light-color;
  color: $medium-color;
}

//style end

.dot {
  width: 10px;
  height: 10px;
  // margin-right: 0.5em;
  display: inline-block;
  // vertical-align: middle;
  border-radius: 50%;
  border: 3px solid #fff;
}

.dot-scheduled {
  border-color: $first-color;
}

.dot-checked-in {
  background-color: $third-color;
  border-color: $third-color;
}

.dot-serving {
  background-color: $second-color;
  border-color: $second-color;
}

.dot-completed {
  border-color: $second-color;
}
.dot-uncompleted {
  border-color: $forth-color;
}
.dot-not-attend {
  border-color: $fifth-color;
}
