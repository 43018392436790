@import "../../../assets/css/color";
@import "../../../assets/css/var";

.overlay {
  position: fixed;
  background-color: #333;
  background-color: rgba(0, 0, 0, 0.75);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  flex-direction: column;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  // transform: scale(0.5);
  z-index: 1000;
  .container {
    background-color: #fff;
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: $border-radius;
    .btn-close {
      outline: none;
      position: absolute;
      text-align: center;
      right: 10px;
      border: 0 none;
      background-color: $light-color;
      line-height: 35px;
      font-size: 1.5em;
      padding: 0;
      top: 10px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      cursor: pointer;
      z-index: 10;
      &:hover {
        background-color: $dark-color;
        color: #fff;
      }
    }
  }
  .size-medium {
    max-width: 700px;
    width: 100%;
    height: 550px;
  }
  .size-small {
    max-width: 400px;
    width: 100%;
    height: auto;
  }
  .size-large {
    max-width: 800px;
    width: 100%;
    height: auto;
    min-height: 50vh;
    max-height: 90vh;
  }
  .size-full {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }
  .type-dialog {
    max-width: 500px;
    height: auto;
  }
}
.header {
  //style for header
  padding: 0.75rem 3rem 0.75rem 2rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $border-color;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  button {
    font-size: 1.2em;
    padding: 0;
    line-height: 1;
    margin-right: 0.5em;
    svg {
      vertical-align: middle;
    }
  }
  .title {
    margin: 0;
    font-size: 1.3em;
    flex: 1;
    font-weight: 600;
  }
}

.body {
  flex: 1;
  overflow: auto;
}
.footer {
  border-top: 1px solid $border-color;
  display: flex;
  justify-content: flex-end;
  padding: 0.75rem 2rem;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  button {
    margin-left: 0.5em;
  }
  // &:after {
  //   clear: both;
  //   content: "";
  //   display: block;
  // }
}

@media only screen and (max-width: 767px) {
  .wrapper {
    .container {
      border-radius: 0;
    }
    .size-medium {
      height: 100vh;
    }
  }
}
