@import "../../assets/css/color";

.input {
  padding: 0.5em;
  padding-right: 0;
  // display: inline-block;
  font-size: 1.5em;
  line-height: 1.3em;
  letter-spacing: 0.5em;
  text-align: center;
  // border: 0 none;
  border-radius: 5px;
  border: 1px solid $border-color;
  outline: none;
  &:focus {
    border: 1px solid $first-color;
  }
}

.align-center {
  text-align: center;
  .input {
    margin: 0.3em;
  }
}
.align-left {
  .input {
    margin-right: 0.5em;
  }
}
