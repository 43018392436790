@import "../../assets/css/color";

.participants {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $table-border-color;
    padding-bottom: 1em;
    margin-bottom: 1em;
    .name {
      flex: 1;
      .label {
        font-size: 0.8em;
        font-weight: 600;
        border: 1px solid $first-color;
        color: $first-color;
        display: inline-block;
        padding: 0 0.5em;
        border-radius: 10px;
        margin-left: 1em;
      }
    }
    .status {
      flex-basis: 120px;
      text-align: center;
    }
  }
}

.confirmed {
  font-size: 0.8em;
  font-weight: 600;
  padding: 0.35em 1.5em;
  border-radius: 30px;
  background-color: $second-color-bg;
  color: $second-color;
}
