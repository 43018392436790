@import "../../../assets/css/color";
@import "../../../assets/css/var";

.header {
  float: left;
}
.nav {
  float: right;
  display: flex;
  button {
    background: transparent;
    border: 1px solid $border-color;
    padding: 0.5em 1em;
    border-radius: 5px;
    margin-left: 2px;
    display: flex;
    font-size: 0.9em;
    align-items: center;
    &:hover {
      background-color: $light-color;
    }
  }
}

.calendar-full {
  .table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    td {
      height: calc((100vh - 100px) / 8);
      vertical-align: top;
      text-align: left;
      border: 1px solid $table-border-color;
    }
    th {
      text-align: left;
      background-color: $light-color;
      font-weight: 600;
      font-size: 0.9em;
      padding: 0.75rem 0.5em;
    }
    th,
    td {
      width: 14%;
    }
    .empty {
      // background-color: $light-color;
      opacity: 0.5;
    }
    .cell {
      min-height: 8em;
      padding: 0.5em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .day {
      font-size: 0.85em;
      color: $text-secondary-color;
    }
  }
}

.calendar-mini {
  max-width: 370px;
  margin: 0 auto;
  .table {
    border-collapse: collapse;
    table-layout: fixed;
    text-align: center;
    clear: both;
    margin: 0 auto;
    td {
      color: $text-secondary-color;
      line-height: 1.3;
      .available {
        color: $text-primary-color;
        font-weight: 500;
        cursor: pointer;
        &:hover > div:not(.selected) {
          background-color: $light-color;
        }
      }
      .not-available {
        opacity: 0.5;
      }
    }
    th {
      font-weight: 600;
      font-size: 0.9em;
      padding: 0.75rem 0.5em;
      border-bottom: 1px solid $border-color;
      color: $text-secondary-color;
    }
    th,
    td {
      width: 14%;
    }
    .empty {
      // background-color: $light-color;
      opacity: 0.5;
    }
    .cell {
      width: 40px;
      height: 40px;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
    .today {
      border: 1px solid $border-color;
    }
    .selected,
    .selected:hover {
      background-color: $first-color;
      color: #fff;
    }
    .day {
      // font-size: 0.85em;
    }
  }
}

.bookings {
  font-size: 0.9em;
}

.dot {
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: $first-color;
  margin-right: 0.5em;
}

// .booking {
//   background-color: $first-color-bg;
//   color: $first-color;
//   padding: 0.25em 0.5em;
//   margin-top: 0.25em;
//   border-radius: 5px;
//   font-weight: 500;
//   cursor: pointer;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   width: 100%;
//   &:hover {
//     background-color: $first-color;
//     color: $first-color-bg;
//   }
//   &.status-21,
//   &.status-22 {
//     // Check-in, Walk-in
//     background-color: $third-color-bg;
//     color: $third-color;
//     &:hover {
//       background-color: $third-color;
//       color: $third-color-bg;
//     }
//   }
//   &.status-41 {
//     // Completed
//     background-color: $second-color-bg;
//     color: $second-color;
//     &:hover {
//       background-color: $second-color;
//       color: $second-color-bg;
//     }
//   }
//   &.status-42 {
//     // Cancelled
//     background-color: $forth-color-bg;
//     color: $forth-color;
//     &:hover {
//       background-color: $forth-color;
//       color: $forth-color-bg;
//     }
//   }
// }
