@import "../../assets/css/color";
@import "../../assets/css/var";

.wrapper {
  //   width: 100%;
  //   height: 100%;
  padding: 2rem;
}
.input-field {
  padding: 1em;
  input {
    max-width: 300px;
  }
}

.line {
  border: 1px solid $border-color;
}
