.item {
  margin-bottom: 1.5em;
  justify-content: flex-start;
  .actions {
    padding-left: 0.5em;
    button {
      height: 100%;
    }
  }
}

.btn-delete {
  font-size: 1.2em;
  line-height: 1;
  vertical-align: middle;
  margin-left: 1em;
}

.btn-edit {
  min-width: 5em;
  padding: 1em;
}
