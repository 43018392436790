@import "../../assets/css/color";
@import "../../assets/css/var";

.photo-input {
  cursor: pointer;
}

.icon {
  font-size: 2em;
  padding: 0.75em;
  color: #fff;
  background-color: $border-color;
  display: block;
  float: left;
  line-height: 1;
  border-radius: $border-radius;
  svg {
    line-height: 1;
  }
}
