@import "../../assets/css/color";
@import "../../assets/css/var";

.booking-details {
  list-style: none;
  padding: 1.5em 1.5em 0.5em;
  margin: 0 0 1em;
  min-height: 22em;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  //   height: 100%;
  li {
    display: flex;
    // margin-top: 1em;
    margin-bottom: 1em;
    .icon {
      min-width: 2.5em;
      // color: $text-secondary-color;
      opacity: 0.5;
      svg {
        font-size: 1.8em;
      }
    }
  }
}
