@import "../../assets/css/color";

.container {
  padding: 1.5em 2em;
}

.new {
  border-bottom: 1px solid $border-color;
  padding-bottom: 0;
}
