/*
Define all the css variable
*/

$border-radius: 5px;

$box-shadow: rgba(11, 37, 75, 0.01) 0px 22px 22px 0px,
  rgba(11, 37, 75, 0.02) 0px 14px 14px 0px,
  rgba(11, 37, 75, 0.02) 0px 4px 4px 0px, rgba(11, 37, 75, 0.02) 0px 2px 2px 0px,
  rgba(11, 37, 75, 0.04) 0px 0px 0px 1px;

$font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
