@import "../../../assets/css/color";
@import "../../../assets/css/var";

.button {
  font-family: inherit;
  background-color: transparent;
  border: 1px solid $border-color;
  padding: 0.5em 2.5em 0.5em 1em;
  border-radius: 5px;
  // font-size: 0.9em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  min-height: 3em;
  position: relative;
  &.disabled {
    background-color: $light-color;
    color: $medium-color;
    cursor: auto;
  }
}

.placeholder {
  color: $text-secondary-color;
  font-weight: 400;
  font-style: italic;
}

.arrow-icon {
  line-height: 1;
  position: absolute;
  top: 1em;
  right: 1em;
}

.menu-label {
  font-weight: 500;
}
