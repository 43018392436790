@import "../../assets/css/color";

.wrapper {
  text-align: center;
  padding: 2em;
  max-width: 400px;
  margin: 0 auto;
  img {
    width: 300px;
  }
}
