@import "../../assets/css/color";
@import "../../assets/css/var";

.input {
  display: flex;
  position: relative;
  &.has-suffix {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.suffix {
  border: 1px solid $border-color;
  border-left: 0 none;
  border-radius: 0 $border-radius $border-radius 0;
  display: flex;
  align-items: center;
  background-color: $light-color;
  padding: 0.5em;
  white-space: nowrap;
}

.count {
  color: $text-secondary-color;
  font-size: 0.8em;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.container {
  display: flex;
}
