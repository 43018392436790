@import "../../assets/css/color";

.aside-container {
  padding-top: 1rem;
  min-height: 100vh;
  // transition: width 0.5s;
  &.collapsed {
    .btn {
      transform: rotate(180deg);
    }
  }
  .logo {
    display: block;
    padding: 0 1.5rem;
    margin-bottom: 1rem;
  }
  .btn {
    width: 2.5em;
    height: 2.5em;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    color: #fff;
    border: 0 none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 3%;
    left: 1rem;
    // transition: transform 0.5s;
    cursor: pointer;
  }
}
.personal {
  background-color: $second-color;
  .btn:hover {
    background-color: #fff;
    color: $second-color;
  }
}
.business {
  background-color: $first-color;
  .btn:hover {
    background-color: #fff;
    color: $first-color;
  }
}

@media only screen and (max-width: 767px) {
  // .aside-container {
  //   width: 60px;
  //   .btn {
  //     left: 0.75rem;
  //   }
  // }
}
