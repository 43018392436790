@import "../../assets/css/color";
@import "../../assets/css/var";

.table {
  font-weight: 600;
  header {
    ul {
      color: $third-color;
      background-color: transparent;
      border-bottom: 1px solid $first-color;
    }
  }
  ul {
    &:nth-child(even) {
      background-color: $eighth-color;
    }
    &:hover {
      box-shadow: none !important;
    }
    li {
      border: 0 none !important;
      padding: 1.5em;
    }
  }
}

.service {
  display: flex;
  align-items: center;

  .label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 2.5em;
    height: 2.5em;
    margin-right: 1em;
    border-radius: $border-radius;
    background-color: $first-color;
  }
}

.button {
  color: $second-color;
}
