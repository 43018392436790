@import "../../assets/css/var";
@import "../../assets/css/color";

// .tag {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   .tag-info {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     border: 1px solid $border-color;
//     border-radius: 10%;
//     min-width: 12em;
//     min-height: 9em;
//     justify-content: space-evenly;
//     label {
//       font-weight: 400;
//       color: $text-secondary-color;
//     }
//     h1 {
//       font-weight: bold;
//       font-size: 2em;
//       position: relative;
//       bottom: 10px;
//     }
//   }
//   .tag-note {
//     color: $text-secondary-color;
//     margin-top: 1em;
//   }
// }

.wrapper {
  width: 20em;
  margin: 0 auto;
  .logo > div {
    margin: 0 auto -1em;
  }
  .container {
    text-align: center;
    border: 1px solid $first-color;
    border-radius: 10px;
    // overflow: hidden;
    padding-bottom: 1em;
    .token-no {
      border-radius: 10px 10px 0 0;
      background-color: $first-color;
      padding: 1em;
      padding-top: 2em;
      > div {
        color: #fff;
      }
    }
    .waiting {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1em 0;
      b {
        background-color: $light-color;
        padding: 0.1em 0.3em;
        border-radius: $border-radius;
        margin-right: 0.2em;
      }
    }
    .service {
      border-top: 1px solid $border-color;
      padding-top: 1em;
      max-width: 80%;
      margin: 0 auto;
    }
  }
}
