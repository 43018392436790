@import "../../assets/css/color";

.container {
  display: flex;
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid $table-border-color;
  .label {
    flex-basis: 7rem;
    color: $text-secondary-color;
  }
  .content {
    flex: 1;
  }
}
