@import "./color";

.container {
  // padding: 1.5rem 2.5rem;
  padding: 1.5rem 0;
}
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  width: 90%;
}

// .footer-action {
// box-shadow: 1px -1px 5px rgba(0, 0, 0, 0.1);
// border-top: 1px solid $table-border-color;
// }

.float-left {
  float: left;
}
.float-right {
  float: right;
}

.separator {
  border-bottom: 1px solid $border-color;
}

.flex-layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}
