/*
Define all the color here
*/

$first-color: #0069f4;
$second-color: #40cd94;
$third-color: #f69826;
$forth-color: #e95997;
$fifth-color: #a867f8;
$sixth-color: #ffc359;
$seventh-color: #24bfd0;
$eighth-color: #11233f;

$first-color-bg: #f1f6fe;
$second-color-bg: #eaf9f3;
$third-color-bg: #fef9f3;
$forth-color-bg: #fcedf4;
$fifth-color-bg: #f6efff;
$sixth-color-bg: #fff8ed;
$seventh-color-bg: #e7f8fa;

$first-color-dark: #0d53c2;
$second-color-dark: #33a375;

$text-primary-color: #1c252c;
$text-secondary-color: #687578;

$border-color: #dadde2;
$table-border-color: #eef0f3;

$light-color: #f5f6fc;
$medium-color: #687578;
$dark-color: #111111;

$hover-color-bg: #f5f6fa;
