@import "../../assets/css/color";

.outer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $dark-color;
  color: #fff;
  font-size: 1.5em;
}

.header {
  background-color: $first-color;
  padding: 0.75em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5em;
  font-weight: 600;
}

.container {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
}
