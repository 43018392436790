@import "../../assets/css/color";

.header {
  overflow: hidden;
  border-bottom: 1px solid $border-color;
  h3 {
    float: left;
  }
  a {
    float: right;
  }
}
