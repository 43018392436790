@import "../../assets/css/color";

.btn {
  background-color: transparent;
  border: 0 none;
  padding: 0;
  color: $first-color;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
