@import "../../assets/css/var";
@import "../../assets/css/color";

.slots {
  list-style: none;
  padding: 0;
  max-width: 580px;
}

.slot {
  border-bottom: 1px solid $border-color;
  padding: 0.75em 0;
}

.empty {
  border: 1px solid $border-color;
  text-align: center;
  padding: 2em 3em;
}
