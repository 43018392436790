@import "../../assets/css/color";
@import "../../assets/css/var";

.calendar-date {
  text-align: center;
  padding: 0.5em;
  border-radius: $border-radius;
  border: 1px solid transparent;
  background-color: $first-color-bg;
  color: $first-color;
  // height: 50px;
  width: 50px;
  box-sizing: border-box;
  .calendar-month {
    font-size: 0.75em;
    font-weight: 500;
    text-transform: uppercase;
  }
  .calendar-day {
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1;
  }
}
.calendar-gray {
  background-color: $border-color;
}

// .day {
//   font-size: 0.9em;
//   color: $text-secondary-color;
//   margin-top: 0.25em;
// }

@media only screen and (max-width: 767px) {
  .calendar-date {
    width: 40px;
  }
}
