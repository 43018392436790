@import "../../assets/css/color";

@media only screen and (max-width: 767px) {
  .table {
    > div ul {
      border-bottom: 1px solid $table-border-color;
      position: relative;
      padding: 1rem 100px 1rem 20px;
      .col-status,
      .col-action,
      .col-actions {
        display: none;
      }
      .col-time {
        position: absolute;
        right: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .col-token {
        position: absolute;
        right: 0;
        top: 40px;
        color: $text-secondary-color;
      }
      .col-customer {
        // clear: both;
      }
      .col-service {
        font-size: 0.9em;
        color: $text-secondary-color;
      }
    }
  }
}
