@import "../../../assets/css/color";
@import "../../../assets/css/var";

.container {
  position: relative;
}

.button {
  border: 0;
  padding: 0.5em 1em;
  font-size: 1em;
  display: block;
  text-align: center;
  // width: 100%;
  font-family: inherit;
  background-color: transparent;
  cursor: pointer;
  min-height: 3em;
}

.btn-wrapper {
  // padding: 10px;
  border-bottom: 1px solid $border-color;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 30vh;
  overflow-y: auto;
  li {
    padding: 0.5em 1.5em;
    cursor: pointer;
    &:hover {
      background-color: $hover-color-bg;
    }
  }
}

.msg-empty {
  padding: 1em;
  color: $text-secondary-color;
  font-style: italic;
}

.cta {
  border-top: 1px solid $border-color;
  // padding: 0.5em;
  text-align: center;
  &:hover {
    background-color: $first-color-bg;
  }
}

.search {
  padding: 10px;
  position: relative;
  .search-icon {
    position: absolute;
    top: 1.2em;
    left: 1.2em;
    color: $text-secondary-color;
    font-size: 1.2em;
  }
  input {
    padding-left: 3em;
  }
}
