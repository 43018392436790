@import "../../assets/css/color";
@import "../../assets/css/var";

.container {
  padding-left: 2rem;
}

.time-slot {
  max-height: 300px;
  overflow-y: auto;
  ul {
    margin: 0;
    list-style: none;
    padding: 0;
    text-align: center;
  }
}

li.separator {
  clear: both;
  padding: 1em 0 0.5em;
  font-weight: 500;
  font-size: 0.85em;
  color: $text-secondary-color;
}

li.item {
  padding: 0.25em;
  width: 25%;
  display: inline-block;
  .btn-time {
    background-color: transparent;
    border: 1px solid $first-color;
    padding: 0.75em 0.5em;
    border-radius: 0.5em;
    line-height: 1;
    position: relative;
    overflow: hidden;
    font-weight: 500;
    text-align: center;
    font-size: 0.85em;
    width: 100%;
    color: $first-color;
    .time-bar {
      background-color: rgba(0, 105, 244, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
    }
    .time-text {
      position: relative;
      z-index: 10;
    }
    &.fulled {
      border-color: $forth-color;
      color: $forth-color;
      // text-decoration: line-through;
      // cursor: default;
      .time-bar {
        background-color: rgba(233, 89, 151, 0.2);
      }
    }
    &.selected,
    &:hover {
      background-color: $first-color;
      color: #fff;
      border-color: $first-color;
    }
  }
}
