@import "../../assets/css/color";

.outer {
  .aside {
    background-color: $light-color;
    padding: 1.5em 1.5em 0;
    .btn-back {
      font-size: 1.5em;
      padding: 0;
      font-family: inherit;
      color: inherit;
      border: 0;
      margin-bottom: 1em;
      cursor: pointer;
      background-color: transparent;
    }
    h3 {
      font-weight: 400;
      margin-bottom: 2em;
      min-height: 2em;
    }
    ul {
      list-style-type: none;
      padding: 0;
      li {
        margin-bottom: 1.5em;
        .number {
          width: 2.25em;
          height: 2.25em;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 0.9em;
          color: $text-secondary-color;
          border: 1px solid $border-color;
        }
        .name {
          // font-weight: 600;
        }
      }
      .completed {
        .number {
          color: white;
          border-color: $second-color;
          background-color: $second-color;
        }
        .name {
          color: $text-secondary-color;
          // color: $second-color;
        }
      }
      .active {
        .number {
          color: $first-color;
          border-color: $first-color;
          // background-color: $first-color;
        }
        .name {
          font-weight: 600;
          color: $first-color;
        }
      }
    }
  }
}

@media only screen and (min-width: 960px) {
  .outer {
    display: flex;
    align-items: stretch;
    min-height: 100vh;
    .aside {
      width: 250px;
      ul {
        li {
          display: flex;
          align-items: center;
          .number {
            margin-right: 1em;
            flex-basis: 2.25em;
          }
        }
      }
    }
    .container {
      flex: 1;
      position: relative;
    }
  }
}
@media only screen and (max-width: 959px) {
  .aside {
    ul {
      display: flex;
      justify-content: space-around;
      li {
        .number {
          margin: 0 auto 0.5em;
        }
      }
    }
  }
}
