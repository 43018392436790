@import "../../../assets/css/color";

.tab-header {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      padding: 0.75rem 2rem;
      // position: relative;
      // bottom: -2px;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        color: $first-color;
      }
      .number {
        color: $first-color;
        background-color: $first-color-bg;
        border-radius: 20px;
        padding: 0 0.5em;
        font-size: 0.8em;
        margin-right: 0.5em;
        display: inline-block;
      }
    }
  }
}

.horizontal {
  .tab-header {
    margin-bottom: 1em;
    &:after {
      clear: both;
      content: "";
      display: block;
    }
    ul {
      border-bottom: 1px solid $border-color;
      display: flex;
      li {
        // text-align: left;
        padding: 0 1rem 0.5rem 1rem;
        position: relative;
        bottom: -1px;
        border-bottom: 1px solid transparent;
      }
      .active {
        color: $first-color;
        border-bottom: 3px solid $first-color;
      }
    }
  }
}

//tab for side
.vertical {
  height: 100%;
  width: 100%;
  .tab-header {
    background-color: $light-color;
    ul {
      li {
        display: block;
      }
      .active {
        background-color: white;
        color: $first-color;
      }
    }
  }
  .tab-body {
    flex: 1;
    overflow: auto;
    padding: 1.5rem 2rem;
  }
}

@media only screen and (min-width: 481px) {
  .vertical {
    display: flex;
    .tab-header {
      flex: 0 1 160px;
      padding-top: 1em;
      ul {
        li {
          display: block;
        }
        .active {
          background-color: white;
          color: $first-color;
        }
      }
    }
    .tab-body {
    }
  }
}

@media only screen and (max-width: 480px) {
  .vertical {
    .tab-header {
      overflow-x: auto;
      width: 100%;
      ul {
        display: flex;
      }
    }
  }
}
