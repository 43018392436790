@import "../../assets/css/color";
@import "../../assets/css/var";

.container {
  border: 1px solid $third-color;
  border-radius: $border-radius;
  // background-color: $third-color-bg;
  padding: 2em;
  .icon {
    color: $third-color;
    font-size: 3em;
  }
  .title {
    // color: $text-secondary-color;
  }
  .message {
    // color: $text-secondary-color;
    font-size: 0.9em;
  }
}
