@import "../../assets/css/color";

.outer {
  .aside {
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.15);
    position: fixed;
    width: 250px;
    left: 0;
    z-index: 10;
    transition: all 0.2s;
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding: 1em 5%;
    width: 100%;
  }
  .content {
    flex: 1;
  }
  .mobile-menu {
    width: 2em;
    height: 2em;
    background-color: $light-color;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $medium-color;
    font-size: 1.5em;
  }
}

@media only screen and (min-width: 768px) {
  .outer {
    .container {
      margin-left: 250px;
      position: relative;
      // transition: margin 0.5s;
      &.with-submenu {
        display: flex;
      }
    }
    &.collapsed {
      .aside {
        width: 70px;
      }
      .container {
        margin-left: 70px;
      }
    }
    .header {
      position: absolute;
      top: 0;
      justify-content: flex-end;
      z-index: 9;
      pointer-events: none;
      .accounts,
      .mobile-menu {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .outer {
    .overlay {
      background-color: #000;
      opacity: 0.7;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      transition: opacity 0.2s;
    }
    .header {
      position: fixed;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
      background-color: #fff;
      top: 0;
      z-index: 9;
    }
    .container {
      padding-top: 5em;
    }
    &.collapsed {
      .aside {
        left: -280px;
      }
      .overlay {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}
