@import "../../assets/css/color";
@import "../../assets/css/var";

@media only screen and (max-width: 767px) {
  .table {
    > div ul {
      border-bottom: 1px solid $table-border-color;
      padding-left: 0;
      padding-right: 0;
      .col-label {
        width: 2.5em;
        background-color: $light-color;
        text-align: center;
        padding: 0.5em 0;
        float: left;
        margin-right: 1em;
        border-radius: $border-radius;
      }
      .col-type {
        color: $text-secondary-color;
        font-size: 0.9em;
      }
      .col-hour,
      .col-actions {
        display: none;
      }
      .col-method {
        clear: both;
        padding-top: 1em;
        font-size: 0.9em;
      }
    }
  }
}
