@import "../../assets/css/color";

.participant-list {
  display: flex;
  align-items: center;
  .participant-pic {
    flex-basis: 80px;
  }
  .participant-details {
    flex: 1;
  }
}
