@import "../../assets/css/var";
@import "../../assets/css/color";

.wrapper {
  padding: 2em;
  max-width: 500px;
  margin: 0 auto;
}
.button {
  display: flex;
  justify-content: flex-end;
}
