@import "../../assets/css/var";
@import "../../assets/css/color";

.form-add-user {
  display: flex;
  > div {
    margin-bottom: 0;
    flex: 1;
  }
  button {
    margin-left: -10px;
    position: relative;
    z-index: 10;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 3.5em;
  }
}
