@import "../../../assets/css/color";
@import "../../../assets/css/var";

.button {
  // border: 1px solid $border-color;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 1.5em;
  color: $text-secondary-color;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    background-color: $light-color;
  }
}
