@import "../../assets/css/color";

.button {
  font-size: 2.5em;
  border: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: $second-color;
  color: white;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid $second-color;
  &:hover {
    background-color: white;
    color: $second-color;
  }
  &.business {
    background-color: $first-color;
    border-color: $first-color;
    &:hover {
      background-color: white;
      color: $first-color;
    }
  }
}

.container {
  position: fixed;
  right: 2vw;
  bottom: 2vw;
}
