/*
CSS for all heading and text
*/

@import "./color";

a {
  color: $first-color;
  text-decoration: none;
}

h1,
.h1 {
  font-size: 2.15em;
  margin: 0;
  font-weight: 600;
}

h2,
.h2 {
  font-size: 1.6em;
  font-weight: 600;
  margin: 0;
}

h3,
.h3 {
  font-size: 1.5em;
  font-weight: 600;
  margin: 0 0 1em;
}

h4,
.h4 {
  font-size: 1.2em;
  font-weight: 600;
  margin: 0;
}

h5,
.h5 {
  font-size: 1em;
  font-weight: 500;
  margin: 0;
}

.page-title {
  margin-bottom: 3em;
  h1 {
    margin: 0;
    line-height: 1.3;
    text-transform: capitalize;
  }
  .desc {
    font-size: 0.9em;
    margin: 0.5em 0;
  }
}
@media only screen and (max-width: 768px) {
  .page-title {
    margin-bottom: 2em;
  }
}
@media only screen and (max-width: 480px) {
  .page-title {
    margin-bottom: 1em;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-secondary {
  color: $text-secondary-color;
}

.text-semibold {
  font-weight: 600;
}

.text-italic {
  font-style: italic;
}

.text-white {
  color: #fff;
}

.text-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.color-secondary {
  color: $second-color;
}

.link {
  font-weight: 500;
  text-decoration: none;
  color: $first-color;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.desc {
  font-size: 0.85em;
  color: $text-secondary-color;
}

.error-msg {
  color: $forth-color;
}

.success-msg {
  color: $second-color;
}
