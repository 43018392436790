@import "./color";
@import "./var";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: #fff;
  color: $text-primary-color;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  line-height: 1.5;
  &.modal-opened {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.modal-open {
  overflow: hidden;
}

.msg-container {
  min-height: 3em;
}

.display-hide {
  display: none;
}

// Hide google recaptcha
.grecaptcha-badge {
  display: none;
}
