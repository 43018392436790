@import "../../assets/css/color";
@import "../../assets/css/var";

.container {
  padding: 2em;
}

.list {
  margin: 0;
  list-style-type: none;
  padding: 1em 0;
  li {
    display: flex;
    border-bottom: 1px solid $table-border-color;
    &:hover {
      background-color: $hover-color-bg;
      cursor: pointer;
    }
    > div {
      padding: 1em;
    }
    .avatar {
      min-width: 80px;
    }
    .business {
      width: 50%;
    }
    .city {
      // width: 40%;
    }
  }
}
