@media only screen and (min-width: 769px) {
  .row {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    justify-content: space-between;
  }
  .col {
    // flex: 1;
  }
  .stretch {
    flex: 1;
  }
}
