@import "../../assets/css/color";
@import "../../assets/css/var";

.datetime {
  display: flex;
  align-items: center;

  .to {
    margin-top: -18px;
  }
}
