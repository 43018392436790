.container {
  position: relative;
}

.date-picker {
  position: relative;
  .icon {
    position: absolute;
    right: 10px;
    top: 15px;
  }
}
