@import "../../assets/css/color";

.dropdown {
  > div {
    width: 200px;
  }
  margin-bottom: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid $border-color;
}
