@import "../../assets/css/color";
@import "../../assets/css/var";

.container {
  // display: flex;
  display: inline-block;
  align-items: center;
}

.btn-click {
  display: flex;
}

.btn {
  padding: 0.75em 1.5em;
  font-size: 0.9em;
  font-weight: 600;
  border: 0 none;
  font-family: inherit;
  // background-color: transparent;
  // color: inherit;
  cursor: pointer;
  line-height: 1;
  border-radius: $border-radius 0 0 $border-radius;
}
.more {
  height: 2em;
  display: flex;
  align-items: center;
  padding: 0 0.5em;
  font-size: 1.2em;
  line-height: 1;
  border-left: 1px solid;
  cursor: pointer;
  border-radius: 0 $border-radius $border-radius 0;
}

.btn-primary-outline {
  border: 1px solid $first-color;
  &.btn {
    border-right: 0 none;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  margin-left: 0.5em;
}
