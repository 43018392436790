@import "../../../assets/css/color";
@import "../../../assets/css/var";

.item {
  display: flex;
  align-items: center;
  // padding: 0.5em;
  cursor: pointer;
  padding: 0.5em 1em;
  &:hover,
  &.selected {
    background-color: $light-color;
  }
  &.disabled {
    background-color: $light-color;
    cursor: not-allowed;
    color: $text-secondary-color;
    opacity: 0.5;
  }
}

.menu {
  // width: 20em;
  // padding: 0.5em;
  ul {
    li {
      &.separator {
        // padding: 0;
        border-top: 1px solid $table-border-color;
      }
      &.label {
        font-size: 0.8em;
        color: $text-secondary-color;
        padding: 0.5em 1em;
        background-color: $light-color;
      }
    }
  }
}

.icon {
  font-size: 1.6em;
  color: $text-secondary-color;
  width: 1.5em;
  line-height: 1;
  svg {
    vertical-align: middle;
  }
}
