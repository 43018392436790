@import "../../assets/css/color";

.container {
  float: left;
  //   background-color: $first-color;
}

.qrcode {
  padding: 1em;
  svg {
    max-width: 100%;
    display: block;
  }
}
