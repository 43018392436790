@import "../../assets/css/color";
@import "../../assets/css/var";

.date {
  font-weight: 600;
  padding: 0.5em 1em;
  border-bottom: 1px solid $table-border-color;
}

.list {
  max-height: 250px;
  overflow-y: auto;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li.item {
      display: flex;
      padding: 0.3em 0.75em;
      margin: 0.3em 0.3em;
      border-radius: $border-radius;
      align-items: center;
      &:hover {
        background-color: $light-color;
        cursor: pointer;
      }
      &.selected {
        background-color: $first-color;
        color: #fff;
        font-weight: 600;
        .bar {
          opacity: 0.5;
        }
      }
      .time {
        flex-basis: 90px;
        text-align: right;
        padding-right: 1.5em;
      }
    }
    li.separator {
      padding: 0;
      color: $text-secondary-color;
      font-size: 0.85em;
      position: relative;
      padding-left: 1em;
      padding-top: 0.5em;
      &:after {
        content: "";
        display: block;
        width: 75%;
        border-bottom: 1px solid $border-color;
        position: absolute;
        right: 0;
        top: 1em;
      }
      &:hover {
        background-color: transparent;
      }
    }
  }
}
