@import "../../assets/css/color";

.input-group {
  margin-bottom: 0.5em;
}
.input-container {
  padding: 0.75rem 0;
}

@media only screen and (min-width: 768px) {
  .input-group {
    &.input-inline {
      display: flex;
      .input-container {
        flex: 1;
      }
    }
  }
}
