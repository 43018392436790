.radio-button {
  padding: 0.75rem 0;
  label {
    padding-left: 0.5em;
  }
}
.radio-group {
  display: inline-block;
  margin-right: 2em;
}
