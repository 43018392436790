@import "../../../assets/css/color";
@import "../../../assets/css/var";

.container {
  // position: absolute;
  // top: 1.5rem;
  // right: 1.5rem;
  pointer-events: auto;
  .btn {
    position: relative;
    font-size: 1.5em;
    width: 2em;
    height: 2em;
    background-color: $light-color;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $medium-color;
    &:hover {
      background-color: $first-color;
      color: #fff;
      cursor: pointer;
    }
  }
  .badge {
    position: absolute;
    top: -0.2em;
    right: -0.2em;
    background-color: $forth-color;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 600;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.list {
  position: absolute;
  right: 0.5rem;
  width: 300px;
  top: 4.5rem;
  max-height: 80vh;
  background-color: #fff;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  overflow: auto;
  pointer-events: auto;
}
