@import "../../assets/css/color";
@import "../../assets/css/var";

.today-booking {
  margin-bottom: 3em;
  h3 {
    display: inline-block;
    margin-right: 0.5em;
  }
}

.container {
  position: relative;
  .wrapper {
    overflow: hidden;
    .slider {
      position: relative;
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      transition: all 0.5s;
      padding: 10px 0;
    }
  }
  .nav {
    font-size: 1.3em;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background-color: $light-color;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $text-secondary-color;
    cursor: pointer;
    &:hover {
      color: $light-color;
      background-color: $text-secondary-color;
    }
    &.nav-prev {
      left: -1em;
    }
    &.nav-next {
      right: -1em;
    }
  }
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: $border-radius;
  // width: 240px;
  margin-right: 20px;
  height: 160px;
  padding: 1.5em;
  border: 1px solid $border-color;
  cursor: pointer;
  .appt-status {
    align-self: flex-end;
    font-size: 0.9em;
    > div {
      width: 8em;
      padding: 0.3em;
    }
  }
  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
}

.booking-no {
  font-size: 1.8em;
  font-weight: bold;
  line-height: 1.3;
}

.card-scheduled {
  border: 1px solid $first-color;
  // &:hover {
  //   background-color: $first-color-bg;
  // }
}

.card-walk-in {
  border: 1px solid $third-color;
  // &:hover {
  //   background-color: $third-color-bg;
  // }
}

.card-serving {
  border: 1px solid $second-color;
  // background-color: $second-color;
  .appt-status > div {
    background-color: $second-color;
    color: #fff;
  }
  .booking-no {
    color: $second-color;
  }
  // &:hover {
  //   background-color: $second-color-bg;
  //   .booking-info {
  //     color: inherit;
  //   }
  // }
}
.card-completed {
  border: 1px solid $second-color;
  .booking-info {
    opacity: 0.3;
  }
  // &:hover {
  //   background-color: $second-color-bg;
  // }
}
