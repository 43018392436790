@import "../../assets/css/color";

.form {
  // background-color: $light-color;
  border-bottom: 1px solid $border-color;
  padding: 1em;
  .wrapper {
    display: flex;
    max-width: 450px;
    margin: 0 auto;
    padding: 1em 0;
    align-items: center;
    .label {
      font-weight: 500;
      flex-basis: 120px;
    }
    .dropdown {
      flex: 1;
    }
  }
}

.date-time {
  max-width: 600px;
  margin: 0 auto;
  padding: 1em 0;
}
