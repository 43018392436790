@import "../../assets/css/color";
@import "../../assets/css/var";

.total {
  font-weight: 600;
  b {
    color: $first-color;
    margin-left: 0.5em;
    font-weight: 600;
    padding: 0.2em 0.75em;
    background-color: $first-color-bg;
    border-radius: 50px;
  }
}

.label {
  display: inline-block;
  width: 1.8em;
  height: 1.8em;
  border-radius: 50%;
  font-weight: 600;
  font-size: 0.9em;
  line-height: 1.8;
  text-align: center;
  color: $first-color;
  background-color: $first-color-bg;
  margin-right: 1em;
}

.title {
  border-bottom: 1px solid $border-color;
  h3 {
    display: inline-block;
    margin-right: 0.5em;
  }
}

@media only screen and (max-width: 767px) {
  .table {
    > div > ul {
      display: flex;
      justify-content: space-between;
      padding-left: 0;
      padding-right: 0;
      border-bottom: 1px solid $border-color;
      .col-name {
        flex: 1;
      }
      .col-incomplete,
      .col-completed {
        min-width: 40px;
      }
    }
  }
}
