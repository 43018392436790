@import "../../assets/css/var";
@import "../../assets/css/color";

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .name {
  }
}

.actions {
  min-width: 160px;
  flex-basis: 35%;
  button {
    padding: 0.5em 1em;
    border-radius: 50px;
    margin-right: 0.5em;
    background-color: $first-color-bg;
    color: $first-color;
    &.selected {
      background-color: $first-color;
      color: #fff;
    }
  }
}
