.container {
  display: flex;
  justify-content: space-between;
  .title {
  }
}
.btn-back {
  font-size: 0.8em;
  padding: 0;
  font-family: inherit;
  color: inherit;
  border: 0;
  margin-bottom: 1em;
  margin-right: 1em;
  cursor: pointer;
  background-color: transparent;
}
