@import "../../assets/css/color";

.container {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 1 60%;
  border-left: 1px solid $first-color;
  border-right: 1px solid $first-color;
  .wrapper {
    text-align: center;
    .number {
      font-size: 8em;
      font-weight: 600;
    }
    .room {
      color: $second-color;
      font-size: 4em;
    }
  }
}

.serving-list,
.waiting-list {
  flex: 1 1 20%;
  h5 {
    color: $third-color;
    padding: 1rem 5%;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      padding: 1.5rem 5%;
      display: flex;
      justify-content: space-between;
    }
  }
}

.serving-list {
  .alternative {
    background-color: #11233f;
  }
}
.waiting-list {
  background-color: #11233f;
  .alternative {
    background-color: $dark-color;
  }
}

.color-white {
  color: white;
}
