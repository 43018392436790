@import "../../assets/css/color";

.content-empty {
  border-top: 1px solid $text-secondary-color;
  background-color: lightgray;
  min-height: 150px;
}

@media only screen and (max-width: 767px) {
  .table {
    > div ul {
      border-bottom: 1px solid $table-border-color;
      padding-left: 50px;
      padding-right: 70px;
      position: relative;
    }
    .col-date {
      position: absolute;
      left: 0;
    }
    .col-time {
      position: absolute;
      right: 0;
      text-align: right;
    }
  }
}
