@import "../../assets/css/color";

.container {
  background-color: $light-color;
  .btn-back {
    line-height: 2;
    display: inline-block;
    vertical-align: middle;
    width: 1.7em;
    height: 1.7em;
    border-radius: 50%;
    font-size: 1rem;
    text-align: center;
    color: $medium-color;
    margin-right: 0.75em;
    &:hover {
      background-color: #fff;
    }
  }
  h3 {
    padding: 0 1.5rem;
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      a {
        display: block;
        padding: 0.75em 1.5rem;
        font-weight: 600;
        cursor: pointer;
        color: $dark-color;
        &:hover {
          color: $first-color;
        }
        &.active {
          background-color: white;
          color: $first-color;
        }
      }
    }
  }
  .booking-page {
    padding-top: 0.5em;
    border-top: 1px solid $border-color;
    a {
      display: flex;
      padding: 0.75em 1.5rem;
      justify-content: space-between;
      font-weight: 600;
      align-items: center;
    }
  }
}
@media only screen and (min-width: 768px) {
  .container {
    position: sticky;
    left: 0;
    top: 0;
    width: 200px;
    height: 100vh;
    padding: 2em 0;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    overflow-x: auto;
    h3 {
      display: none;
    }
    ul {
      display: flex;
      margin: 0;
      li {
        .nav-name {
          white-space: nowrap;
        }
      }
    }
  }
}
