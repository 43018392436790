@import "../../assets/css/color";
@import "../../assets/css/var";

.container {
  display: flex;
  align-items: center;

  .input {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    // overflow: hidden;
    margin-right: 1em;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      top: 0;
      background-color: #fff;
      opacity: 0;
      transition: opacity 0.3s;
    }
    &.checked:before {
      opacity: 0.7;
      bottom: 0;
    }
  }
  .dropdown {
    border: 0 none;
    width: 4.75em;
  }

  .label {
    padding: 0.75em;
    border-left: 1px solid $border-color;
    background-color: $light-color;
    color: $text-secondary-color;
    &.hour-label {
      border-right: 1px solid $border-color;
    }
  }
  .checkbox {
    cursor: pointer;
    display: flex;
  }
}
