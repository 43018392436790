/*
Spacing css
The classes are named using the format {property}{sides}-{size}
Where property is one of:
m - for classes that set margin
p - for classes that set padding

Where sides is one of:
t - for classes that set margin-top or padding-top
b - for classes that set margin-bottom or padding-bottom
l - for classes that set margin-left or padding-left
r - for classes that set margin-right or padding-right

Examples

.mt-5 {
  margin-top: 0.5em !important;
}

*/
$spaceamounts: ();
@for $i from 0 through 40 {
  $spaceamounts: append($spaceamounts, $i);
}
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{$space} {
      margin: #{$space / 10}em !important;
    }
    .mx-#{$space} {
      margin-left: #{$space / 10}em !important;
      margin-right: #{$space / 10}em !important;
    }
    .my-#{$space} {
      margin-top: #{$space / 10}em !important;
      margin-bottom: #{$space / 10}em !important;
    }

    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space / 10}em !important;
    }

    .p-#{$space} {
      padding: #{$space / 10}em !important;
    }
    .px-#{$space} {
      padding-left: #{$space / 10}em !important;
      padding-right: #{$space / 10}em !important;
    }
    .py-#{$space} {
      padding-top: #{$space / 10}em !important;
      padding-bottom: #{$space / 10}em !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space / 10}em !important;
    }
  }
}
