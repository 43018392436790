@import "../../assets/css/color";

.wrapper {
  max-width: 500px;
  .toggle {
    padding: 2em 0;
    border-top: 1px solid $border-color;
    display: flex;
    align-items: center;
  }
}
