@import "../../../assets/css/color";

.outer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.header {
  // padding: 1.5rem 2.5rem 0;
  padding: 1.5rem 0 0;
}

.body {
  flex: 1;
  // padding-left: 2.5rem;
  // padding-right: 2.5rem;
}

.footer {
  overflow: hidden;
  padding-top: 1em;
  // padding: 1rem 2.5rem;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  // background-color: #fff;
  .footer-action {
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.15);
    // padding: 1rem 2.5rem;
    padding: 1rem 0;
    background-color: #fff;
    > div:after {
      display: block;
      content: "";
      clear: both;
    }
  }
}

.btn-back {
  padding-left: 0;
  padding-right: 0;
  svg {
    vertical-align: middle;
    margin-right: 0.25em;
  }
}

.btn-header-back {
  font-size: 0.5em;
  width: 1.75em;
  height: 1.75em;
  border-radius: 50%;
  display: inline-block;
  padding-left: 0.3em;
  line-height: 2em;
  vertical-align: middle;
  color: $dark-color;
  margin-right: 1em;
  &:hover {
    background-color: $light-color;
  }
}
