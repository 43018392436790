@import "../../assets/css/color";
@import "../../assets/css/var";

.number {
  font-size: 5em;
  font-weight: 600;
  margin-bottom: 0.2em;
}

.desc {
  color: $text-secondary-color;
}
