@import "../../assets/css/color";
@import "../../assets/css/var";

/* Switches */
.switches {
  border: 0;
  background-color: #ccc;
  border-radius: 30px;
  width: 40px;
  height: 20px;
  padding: 2px;
  text-align: left;
  line-height: 1;
  cursor: pointer;
  .switches-button {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #fff;
    display: inline-block;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    transition: transform 0.5s;
  }
}
.switches-checked {
  background-color: $second-color;
  .switches-button {
    transform: translateX(20px);
  }
}

.container {
  display: flex;
}

.label {
  margin-left: 1em;
}
