@import "../../assets/css/color";
@import "../../assets/css/var";

.date-picker {
  display: flex;
  .clear-filter {
    border: 0 none;
    background-color: transparent;
    line-height: 1;
    margin-left: 0.5em;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    align-self: center;
    &:hover {
      background-color: $light-color;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 767px) {
  .table {
    > div ul {
      border-bottom: 1px solid $table-border-color;
      position: relative;
      padding: 1rem 100px 1rem 20px;
      .col-status,
      .col-action,
      .col-actions {
        display: none;
      }
      .col-time {
        position: absolute;
        right: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .col-service {
        font-size: 0.9em;
        color: $text-secondary-color;
      }
    }
  }
}
