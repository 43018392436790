@import "../../assets/css/color";

.image {
  opacity: 0.25;
  &.image-large {
    max-width: 120px;
    margin: 0 auto 1em;
  }
  &.image-medium {
    max-width: 50px;
    margin: 2em auto 1em;
  }
}
.icon {
  color: $medium-color;
  opacity: 0.5;
  font-size: 2em;
}

.container {
  padding: 2em;
}

.desc {
  max-width: 600px;
  margin: 0 auto;
}
