@import "../../assets/css/color";
@import "../../assets/css/var";

.photo-input {
  cursor: pointer;
  width: 180px;
  height: 90px;

  .icon {
    font-size: 2em;
    padding: 0.75em;
    color: #fff;
    background-color: $border-color;
    display: block;
    float: left;
    line-height: 1;
    border-radius: $border-radius;
    svg {
      line-height: 1;
    }
  }

  img {
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 100px;
    overflow: hidden;
    object-fit: cover;
  }
}
