@import "../../assets/css/color";

.wrapper {
  max-width: 600px;
  padding: 1em 0;
  margin: 0 auto;
  list-style: none;
  li {
    display: flex;
    border-bottom: 1px solid $border-color;
    padding: 1em 0;
    .label {
      color: $text-secondary-color;
      flex-basis: 100px;
    }
    .value {
      font-weight: 500;
    }
  }
}
