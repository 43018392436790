@import "../../assets/css/color";

.status {
  border-radius: 30px;
  padding: 0.5em 1.5em;
  font-size: 0.8em;
  font-weight: 600;
  margin: 0.3em 1em 0.3em 0;
  display: inline-block;
  // width: 10em;
}

.advance {
  background-color: $first-color-bg;
  color: $first-color;
}

.walk-in {
  background-color: $second-color-bg;
  color: $second-color;
}
