@import "../../assets/css/color";

.data-list {
  margin: 0;
  list-style-type: none;
  padding: 0;
  border-top: 1px solid $border-color;
  li {
    padding: 1em 0 1em 0;
    border-bottom: 1px solid $border-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 4em;
    .col-data {
      flex: 1;
    }
    .col-edit {
      flex-basis: 50px;
    }
    .col-delete {
      flex-basis: 30px;
      font-size: 1.2em;
      button {
        color: $medium-color;
        &:hover {
          color: $first-color;
        }
      }
    }
  }
}

.btn-toggle {
  // border-top: 1px solid $border-color;
  display: flex;
  justify-content: center;
  color: $first-color;
  font-weight: 600;
  padding: 1em;
  width: 100%;
  &:hover {
    text-decoration: underline;
  }
  .icon {
    font-size: 1.2em;
    flex-basis: 35px;
    line-height: 1;
    vertical-align: middle;
    display: inline-block;
  }
}

.btn-cta {
  display: block;
  width: 100%;
  font-size: 1em;
  padding: 0.75em;
}
