@import "../../assets/css/color";
@import "../../assets/css/var";

.btn-block {
  width: 100%;
}

// .btn-secondary {
//   background-color: $second-color;
// }

.btn-large {
  font-size: 1em;
}

.btn-small {
  font-size: 0.85em;
  padding: 0.75em 1.5em;
}
