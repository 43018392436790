@import "../../assets/css/color";

.react-calendar {
  width: 360px;
  button {
    font-family: inherit;
    font-size: inherit;
    background-color: transparent;
    border: 0 none;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
  abbr {
    text-decoration: none;
  }
  .react-calendar__navigation {
    border-bottom: 1px solid $border-color;
    display: flex;
    .react-calendar__navigation__arrow {
      font-size: 1.5em;
      padding: 0.25em 0.5em;
    }
    .react-calendar__navigation__label__labelText {
      font-weight: 600;
      font-size: 1.1em;
    }
    button:hover {
      background-color: $light-color;
    }
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    color: $text-secondary-color;
    // font-weight: 500;
    font-size: 0.9em;
    padding: 0.5em 0;
    border-bottom: 1px solid $border-color;
  }
  .react-calendar__month-view__days {
    padding: 0.25em 0;
  }
  .react-calendar__tile {
    abbr {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      padding: 0;
      &:hover {
        background-color: $light-color;
      }
    }
    &.react-calendar__month-view__days__day abbr {
      width: 2.5em;
      height: 2.5em;
      border-radius: 50%;
    }
    &.react-calendar__year-view__months__month abbr {
      padding: 1em;
    }
    &.react-calendar__decade-view__years__year {
      padding: 1em;
      &:hover {
        background-color: $light-color;
      }
    }
    &.react-calendar__tile--now abbr {
      border: 1px solid $border-color;
    }
    &.react-calendar__tile--active abbr {
      background-color: $first-color;
      color: #fff;
      font-weight: 600;
    }
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.2;
  }
}
