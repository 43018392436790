@import "../../assets/css/color";
@import "../../assets/css/var";

.checkbox {
  // background-color: $medium-color;
  border: 1px solid $border-color;
  flex-basis: 20px;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  vertical-align: middle;
  color: #fff;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
}

.checked {
  background-color: $second-color;
  border-color: $second-color;
}

.lg {
  width: 30px;
  height: 30px;
}
