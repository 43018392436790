@import "../../assets/css/var";
@import "../../assets/css/color";

.label {
  font-size: 1.3em;
  background-color: rgba(255, 255, 255, 0.1);
  color: $second-color;
  display: inline-block;
  padding: 0 0.5em;
  border-radius: $border-radius;
  margin-right: 0.25em;
  vertical-align: middle;
}
.desc {
  font-size: 0.8em;
}
