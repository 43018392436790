@import "../../../assets/css/color";

.container {
  display: flex;
  align-items: center;
}

.meta {
  margin-left: 1em;
  overflow: hidden;
}
