.wrapper {
  padding: 2rem 2rem 0;
}

.content {
  padding: 1.5em 0 0;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 2rem 2rem 1.5rem;
  button {
    margin-left: 0.5em;
  }
}
