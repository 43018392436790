@import "../../assets/css/color";

.show-all {
  display: flex;
  float: left;
  align-items: center;
  margin: 0.5em 0;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .table {
    > div > ul {
      border-bottom: 1px solid $table-border-color;
      position: relative;
      padding: 1rem 70px 1rem 50px;
      .col-date {
        position: absolute;
        left: 0;
      }
      .col-time {
        position: absolute;
        right: 0;
        width: auto;
        min-width: 0;
        text-align: right;
      }
      .col-services {
        width: auto;
      }
      .col-status,
      .col-action,
      .col-type {
        display: none;
      }
    }
  }
}
