@import "../../../assets/css/color";

.slot {
  display: flex;
  &:not(:first-child) {
    margin-top: 1em;
  }
}
.time-options {
  flex-basis: 90px;
}
.separator {
  flex-basis: 10px;
  height: 1px;
  background-color: $text-primary-color;
  margin: 1em;
}
.time-slot-actions {
  flex: 1;
  overflow: hidden;
  padding-left: 1em;
  display: flex;
  align-items: center;
}
