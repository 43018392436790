@import "../../assets/css/color";
@import "../../assets/css/var";

.invitation {
  background-color: $first-color;
  padding: 2em;
  color: #fff;
  border-radius: $border-radius;
  margin-bottom: 2em;
  p {
    margin: 0 0 1.5em;
  }
  .btn-accept {
    background-color: #fff;
    color: $first-color;
  }
  .btn-reject {
    border-color: #fff;
    margin-left: 1em;
  }
}

.business {
  .logo > div {
    margin: 0 auto 1em;
  }
  text-align: center;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: 2em;
  margin-bottom: 2em;
}
