@import "../../assets/css/color";

.avatar-name {
  // display: flex;
  // align-items: center;
  position: relative;
  padding-left: 70px;
  min-height: 50px;
  line-height: 50px;
  .avatar {
    position: absolute;
    left: 0;
    top: 0;
  }
  .name {
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
    max-width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .table {
    > div > ul {
      border-bottom: 1px solid $table-border-color;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 0;
      padding-right: 0;
      .col-name {
        flex: 1;
        max-width: 90%;
      }
      .col-city,
      .col-industry,
      .col-next-appt,
      .col-make-appt {
        display: none;
      }
      .col-action {
        width: 30px;
      }
    }
  }
}
