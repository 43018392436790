@import "../../assets/css/color";

.calendar {
  border-top: 1px solid $border-color;
  padding-top: 2em;
}

.view-switch {
  margin-bottom: 2em;
  width: 160px;
  button {
    min-width: 100%;
  }
}
