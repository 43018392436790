@import "./color";
@import "./var";

button {
  cursor: pointer;
}

.btn {
  padding: 0.75em 2em;
  display: inline-block;
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  border: 1px solid transparent;
  border-radius: $border-radius;
  cursor: pointer;
  outline: none;
  font-weight: 600;
  font-size: 0.9em;
  font-family: inherit;
  line-height: 1.15;
}

.btn-link {
  background-color: transparent;
  border: 0;
  color: $first-color;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
}

.btn-empty {
  // padding: 0;
  border: 0 none;
  background-color: transparent;
  font-size: inherit;
  cursor: pointer;
  outline: none;
  font-weight: 600;
  font-size: 0.9em;
  font-family: inherit;
  color: $first-color;
}

.btn-primary {
  background-color: $first-color;
  color: #fff;
  border-color: $first-color;
  &:hover {
    background-color: $first-color-dark;
  }
}

.btn-primary-outline {
  background-color: transparent;
  color: $first-color;
  border-color: $first-color;
  &:hover {
    background-color: $first-color-bg;
  }
}

.input-control {
  width: 100%;
  display: block;
  padding: 0.75em;
  border: 1px solid $border-color;
  font-size: inherit;
  color: inherit;
  font-family: inherit;
  border-radius: $border-radius;
  background-color: transparent;
  line-height: 1.5;
  &:disabled {
    background-color: $light-color;
    cursor: not-allowed;
  }
}

.btn:disabled {
  cursor: default;
  opacity: 0.3;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-style: italic;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-style: italic;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  font-style: italic;
}

/* Hide arrow for input number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
