@import "../../assets/css/var";
@import "../../assets/css/color";

.wrapper {
  max-width: 480px;
  margin-bottom: 4em;
}

.item {
  .value {
    overflow: hidden;
  }
}
