@import "../../assets/css/color";

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  // margin-left: -20px;
  // margin-top: -20px;
  animation: loading-bar-spinner 600ms linear infinite;
}

.icon {
  width: 40px;
  height: 40px;
  border: solid 4px transparent;
  border-top-color: $second-color;
  border-left-color: $second-color;
  border-radius: 50%;
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
