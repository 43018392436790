@import "../../assets/css/color";

.container {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid $first-color;
  .wrapper {
    text-align: center;
    .number {
      font-size: 8em;
      font-weight: 600;
    }
    .time {
      color: $second-color;
      font-size: 4em;
    }
  }
}
.next-list {
  flex-basis: 25%;
  border-right: 1px solid $first-color;
  h5 {
    color: $third-color;
    padding: 1rem 1.5rem;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 1rem 1.5rem;
      .number {
        font-size: 3em;
        font-weight: 600;
      }
      .time {
        color: $second-color;
      }
      &.alternative {
        background-color: #11233f;
      }
    }
  }
}

.color-bg {
  background-color: $eighth-color;
}
