@import "../../assets/css/color";
@import "../../assets/css/var";

.list {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  list-style: none;
  margin: 0;
  padding: 0;
  &:last-child {
    border-bottom: 0px;
  }
  li {
    padding: 1.5em 2em;
    border-bottom: 1px solid $border-color;
    .icon {
      font-size: 2em;
      color: $first-color;
    }
    button {
      width: 100%;
    }
  }
}
