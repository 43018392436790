@import "../../assets/css/color";

.wrapper {
  max-width: 500px;
}

.pin {
  font-size: 1.5em;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  padding: 0.5em 0;
}
