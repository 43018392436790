@import "../../../assets/css/color";
/*
Example:
<div class="tooltip">Hover over me
  <span class="tooltiptext tooltip-left">Tooltip text</span>
</div>
*/
.tooltip {
  position: relative;
  display: inline-block;
  // cursor: help;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: $dark-color;
  color: #f5f6fc;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  font-size: 0.85em;
  font-weight: 500;
  &.tooltip-top {
    // bottom: 150%;
    bottom: 100%;
    left: 50%;
    margin-left: -100px;
  }
  &.tooltip-bottom {
    // top: 150%;
    top: 100%;
    left: 50%;
    margin-left: -100px;
  }
  &.tooltip-left {
    top: -5px;
    right: 150%;
  }
  &.tooltip-right {
    top: -5px;
    left: 150%;
  }
}

.icon {
  font-size: 1.2em;
  margin: 0 0.25em;
  color: $first-color;
}

//Make arrow of the tooltip
.tooltip .tooltiptext.tooltip-top::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  border-color: black transparent transparent transparent;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
}
.tooltip .tooltiptext.tooltip-bottom::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  border-color: transparent transparent black transparent;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
}
.tooltip .tooltiptext.tooltip-left::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%;
  border-color: transparent transparent transparent black;
  // margin-left: -5px;
  border-width: 5px;
  border-style: solid;
}
.tooltip .tooltiptext.tooltip-right::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  border-color: transparent black transparent transparent;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
}
//Make arrow of the tooltip end

.tooltip:hover .tooltiptext {
  visibility: visible;
}
