@import "../../assets/css/color";
@import "../../assets/css/var";

.container {
  padding: 0 0.5rem;
  // margin-bottom: 1em;
  .trigger {
    display: flex;
    align-items: center;
    // border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: $border-radius;
    padding: 0.75em 1em;
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
    .current-name {
      margin-left: 1em;
      // font-size: 1.2em;
      color: #fff;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 150px;
      overflow: hidden;
    }
  }
  .is-header {
    background-color: $light-color;
    padding: 0.5em 1em;
    .current-name {
      color: $text-primary-color;
    }
  }
}

.current-role {
  text-align: center;
  // min-width: 20em;
  padding: 1em;
  .pic {
    display: flex;
    justify-content: center;
    margin: 0.5em;
  }
  .name {
    max-width: 205px;
    margin: 0 auto 0.25em;
  }
  .role {
    text-transform: capitalize;
    margin-bottom: 1em;
  }
  a {
    text-transform: capitalize;
  }
}

.menu-list {
  min-width: 230px;
  li {
    border-top: 1px solid $table-border-color;
    .link {
      padding: 0.75em 1em;
    }
    .btn {
      display: flex;
      padding: 0.75em 1em;
      cursor: pointer;
      align-items: center;
      &:hover {
        background-color: $hover-color-bg;
      }
      .meta {
        padding-left: 0.5em;
        .name {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 170px;
        }
      }
    }
  }
  a:hover {
    background-color: $hover-color-bg;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    &.collapsed {
      .trigger {
        padding: 0.75em;
        border: 0 none;
        justify-content: center;
      }
      .current-name {
        display: none;
      }
    }
  }
}
