@import "../../../assets/css/color";
@import "../../../assets/css/var";

.notification {
  padding: 1em;
  border-top: 1px solid $border-color;
  display: flex;
  font-size: 0.9em;
  &:hover {
    background-color: $light-color;
    cursor: pointer;
  }
  .image {
    object-fit: cover;
    flex-basis: 50px;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    overflow: hidden;
  }
  .text {
    flex: 1;
    padding-left: 1em;
    .date {
      margin-top: 0.2em;
    }
  }
}

.read {
  background-color: $light-color;
  .text {
    opacity: 0.7;
  }
}
