.item {
  padding: 0.25em;
  margin-bottom: 0.25em;
  border: 0 none;
}

.group {
  display: flex;
  .day-name {
    flex-basis: 8em;
    margin-bottom: 0.2em;
  }
  .hours {
    flex: 1;
  }
}
