@import "../../../assets/css/color";
@import "../../../assets/css/var";

.toast-success {
  background-color: $second-color;
}
.toast-info {
  background-color: $first-color;
}
.toast-error {
  background-color: $forth-color;
}
.toast-warning {
  background-color: $sixth-color;
}
.toast-default {
}

.btn-close {
  color: #fff;
  font-size: 1.5em;
  align-self: flex-start;
  margin-top: 0.5em;
  padding: 0 0.5em;
}

//Box styles
.container {
  display: flex;
  color: #fff;
  font-family: $font-family;
  .icon {
    margin: 0.25em 1em 0.25em 0;
    svg {
      font-size: 2em;
    }
  }
  .message {
    h4 {
      margin: 0 0 0.5em;
    }
    p {
      margin: 0 0 0.5em 0;
      font-weight: 500;
    }
  }
}
//Box styles end
