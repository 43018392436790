.outer {
  // min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0;
}

.container {
  max-width: 420px;
  width: 90%;
  min-height: 400px;
}

.btn-close {
  position: fixed;
  top: 3vh;
  right: 5vw;
  font-size: 1.5em;
  cursor: pointer;
}
