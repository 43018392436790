@import "../../../assets/css/color";

.avatar {
  background-color: $text-secondary-color;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.circle {
  border-radius: 50%;
}
.square {
  border-radius: 20%;
}
