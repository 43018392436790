@import "../../assets/css/var";
@import "../../assets/css/color";

.container {
  display: flex;
}

.slot-display {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.action {
  flex-basis: 60px;
  padding-top: 0.5em;
}

.label {
  flex-basis: 150px;
  padding-right: 10px;
}
.timeslot {
  flex: 1;
}

.date {
  flex-basis: 150px;
}

.remove {
  flex-basis: 30px;
  font-size: 1.5em;
  padding-top: 0.3em;
  line-height: 1;
}
