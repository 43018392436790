@import "../../assets/css/color";
@import "../../assets/css/var";

.container {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: 2em;
  .send-container {
    display: flex;
    .input {
      width: 100%;
      padding: 0.75em;
      border: 1px solid #dadde2;
      font-size: inherit;
      color: inherit;
      font-family: inherit;
      border-radius: 5px;
      background-color: transparent;
    }
    .button {
      margin-left: -5px;
    }
  }
}
