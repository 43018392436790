@import "../../assets/css/color";
@import "../../assets/css/var";

.custom-edit-container {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: 1em;
}
.button {
  display: flex;
  justify-content: flex-end;
}
