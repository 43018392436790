@import "../../../assets/css/color";

.btn-remove {
  width: 3em;
  min-height: 1em;
  button {
    padding: 0.2em;
    font-size: 1.6em;
    color: $text-primary-color;
    line-height: 1;
  }
}
.btn-add {
  width: 8em;
  button {
    padding: 0.5em 0;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    display: flex;
    flex: 1;
    .switch {
      padding-top: 0.75em;
      flex-basis: 50px;
    }
  }
  .label {
    flex-basis: 120px;
    padding: 0.75em 0;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    margin-bottom: 2em;
    flex-wrap: wrap;
    display: flex;

    .slot {
      flex-basis: 100%;
    }
  }
  .label {
    flex-basis: 80%;
    margin-bottom: 0.5em;
    font-weight: 600;
  }
}
