@import "../../assets/css/color";
@import "../../assets/css/var";

.button {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin: 10px;
  .icon {
    line-height: 1;
  }
  .label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

//cta styles
.add-icon {
  font-size: 1.8em;
  vertical-align: middle;
  display: inline-block;
  line-height: 1;
  margin-right: 0.1em;
}

.btn-create-new {
  font-size: 1em;
  width: 100%;
  padding: 0.5em;
}
//cta styles end
