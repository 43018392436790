@import "../../../assets/css/color";
@import "../../../assets/css/var";

@media only screen and (min-width: 769px) {
  .container {
    // display: table;
    width: 100%;
    // border-collapse: collapse;
    // table-layout: fixed;
  }
  .header {
    // display: table-header-group;
    // overflow: hidden; //new add

    > ul {
      background-color: $light-color;
      // border-bottom: 1px solid $border-color;
      // border-top: 1px solid $border-color;
      // border: 1px solid $border-color; //command this
      // border-radius: 15px 15px 0 0px; //new add
      // box-shadow: 0 0 1px 0; //new add
      > li {
        font-weight: 600;
        // color: $text-secondary-color;
        font-size: 0.9em;
        padding: 0.75rem 1.5%;
      }
    }
  }
  .header,
  .content {
    // display: table-header-group;
    // overflow: hidden; //new add

    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      > li {
        // display: flex;
        // flex-direction: column;
        flex-grow: 1;
        // justify-content: center;
        &:not(.align-top) {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
  .content {
    // display: table-row-group;
    > ul {
      &.clickable {
        // border: 1px solid $border-color;
      }
      &.clickable:hover {
        // background-color: $hover-color-bg;
        cursor: pointer;
        box-shadow: 0 0px 10px $border-color;
      }
      > li {
        border-bottom: 1px solid $table-border-color;
        padding: 0.75rem 1.5%;
        // vertical-align: middle;
      }
    }
  }

  //pagination styles start
  .pagination {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .row-select {
      display: flex;
      align-items: center;
    }
    .page-select {
    }
    .first-page-button,
    .last-page-button,
    .prev-page-button,
    .next-page-button,
    .page-number {
      color: $first-color;
      padding: 1em;
      background-color: transparent;
      border: 0;
      display: inline-block;
      border-radius: 5px;
      cursor: pointer;
      outline: none;
      font-weight: 600;
      font-size: 0.9em;
      font-family: inherit;
    }
    .page-number {
      margin: 0 1em;
      padding: 0;
    }
  }
  //pagination styles end
}
@media only screen and (max-width: 768px) {
  .header {
    display: none;
  }
  .content {
    > ul {
      padding: 1rem;
      // display: block;
      list-style: none;
      margin: 0;
      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }
  }
}
