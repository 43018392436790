@import "../../../assets/css/color";

.container {
  text-align: center;
  h4 {
    padding: 1em;
    border-bottom: 1px solid $border-color;
  }
  .upload-photo {
    padding: 1em;
    position: relative;
    color: $first-color;
    font-weight: 600;
    border-bottom: 1px solid $border-color;
    &.disabled {
      opacity: 0.5;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      outline: none;
      // display: none;
      opacity: 0;
      z-index: 1;
      width: 100%;
      cursor: pointer;
    }
  }
}
