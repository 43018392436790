@import "../../../assets/css/color";

.pic {
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  border: 1px solid $light-color;

  img {
    max-width: 100%;
    // max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
}

.size-medium {
  width: 240px;
  height: 160px;
  border-width: 3px;
  border-radius: 0;
}

.size-big {
  width: 6em;
  height: 6em;
  border-width: 3px;
  flex-basis: 6em;
  min-width: 6em;
}

.size-small {
  width: 2em;
  height: 2em;
  flex-basis: 2em;
  min-width: 2em;
}
