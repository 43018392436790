.header {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  button {
    line-height: 1;
    padding: 0.75em 0.75em 0.75em 0;
  }
  h4 {
    flex: 1;
  }
}

.content {
  margin-bottom: 1em;
}

.wrapper {
  max-width: 600px;
  padding: 2rem;
  margin: 0 auto;
}
