@import "../../assets/css/color";

.menu-container {
  margin-top: 1em;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      position: relative;
      a {
        color: #fff;
      }
    }
  }
  &.collapsed {
    .menu-item {
      .name {
        display: none;
      }
    }
  }
}

.menu-item {
  color: #fff;
  font-weight: 600;
  // font-size: 1.15em;
  display: block;
  padding: 0.75em 0;
  cursor: pointer;
  .icon {
    display: inline-block;
    font-size: 1.3em;
    vertical-align: middle;
    padding: 0 1.5rem;
    opacity: 0.5;
  }
  .name {
    opacity: 1;
  }
  &:hover *,
  &.active * {
    opacity: 1;
  }
  &.active {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.menu-has-children {
  position: relative;
  .dropdown-icon {
    cursor: pointer;
    position: absolute;
    top: 1em;
    right: 1em;
    color: white;
    line-height: 1;
    // transition: transform 0.5s;
  }
  .sub-menu {
    padding-left: 5em;
    overflow: hidden;
    ul {
      margin-top: -120%;
      // transition: margin-top 0.5s;
      padding-bottom: 1em;
    }
    a {
      font-size: 0.9em;
      color: #fff;
      font-weight: 500;
      display: block;
      padding: 0.5em 0;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.submenu-expanded,
.submenu-active {
  background-color: rgba(255, 255, 255, 0.2);
  .icon {
    opacity: 1;
  }
  .dropdown-icon {
    transform: rotate(180deg);
  }
  .sub-menu {
    ul {
      margin-top: 0;
    }
  }
}

.separate-link {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 0.5em;
}
.sub-links {
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75em 1.5rem;
    font-weight: 500;
    font-size: 0.9em;
    .icon {
      font-size: 1.2em;
      vertical-align: middle;
      line-height: 1;
    }
  }
}

@media only screen and (min-width: 768px) {
  .menu-container {
    &.collapsed {
      .dropdown-icon {
        display: none;
      }
      .sub-menu {
        position: absolute;
        top: 0;
        background-color: $dark-color;
        padding-left: 0;
        left: 70px;
        font-size: 0.8em;
        white-space: nowrap;
        display: none;
        ul {
          margin: 0;
          padding: 0;
          a {
            padding: 0.5em 1em;
          }
        }
      }
      .submenu-expanded {
        .sub-menu {
          display: block;
        }
      }
    }
  }
}

// @media only screen and (max-width: 767px) {
//   .menu-container {
//     .menu-item {
//       text-align: center;
//       .icon {
//         padding: 0;
//         // font-size: 1.5em;
//       }
//       .name {
//         display: block;
//         font-size: 0.7em;
//       }
//     }
//     .menu-has-children {
//       .dropdown-icon {
//         display: none;
//       }
//       .sub-menu {
//         position: absolute;
//         top: 0;
//         background-color: $dark-color;
//         padding-left: 0;
//         left: 70px;
//         font-size: 0.8em;
//         white-space: nowrap;
//         display: none;
//         ul {
//           margin: 0;
//           padding: 0;
//           a {
//             padding: 0.5em 1em;
//           }
//         }
//       }
//     }
//     .submenu-expanded {
//       .sub-menu {
//         display: block;
//       }
//     }
//   }
// }
