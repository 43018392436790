@import "../../../assets/css/color";
@import "../../../assets/css/var";

.container {
  position: relative;
}

.time-selection {
  display: flex;
  height: 200px;
  .list {
    overflow: auto;
    width: 4rem;
    height: 200px;
    border-right: 1px solid $border-color;
    list-style: none;
    padding: 0;
    margin: 0;
    &:hover {
      // overflow-y: scroll;
    }
    &.ampm-list {
      border: 0 none;
    }
    li {
      padding: 0.25rem 0.75rem;
      cursor: pointer;
      &:hover {
        background-color: $light-color;
      }
      &.selected {
        background-color: $first-color;
        color: #fff;
      }
      &.disabled {
        opacity: 0.5;
      }
    }
  }
}
