@import "../../assets/css/color";
@import "../../assets/css/var";

.container {
  position: relative;

  .count {
    color: $text-secondary-color;
    font-size: 0.8em;
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
}

.input {
  resize: none;
}
