@import "../../../assets/css/color";
@import "../../../assets/css/var";

.container {
  position: relative;
  // display: inline-block;
}

.hidden {
  position: fixed;
  bottom: 0;
  left: 0;
}

.button {
  border: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background-color: transparent;
  cursor: pointer;
}

.arrow {
  vertical-align: middle;
  display: inline-block;
  margin-left: 0.5em;
}

.dropdown {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.dropdown-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.menu {
  position: absolute;
  // position: fixed;
  background-color: #fff;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  overflow: hidden;
  z-index: 1100;
  // margin: 5px 0;
  // min-width: 100%;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    // a {
    //   display: block;
    //   padding: 1em 1.5em;
    // }
    li {
      // padding: 0.5em 1em;
      // cursor: pointer;
      // display: block;
      // &:hover {
      //   background-color: $hover-color-bg;
      // }
    }
  }
}
