@import "../../assets/css/color";

.result {
  padding: 0 1em 0;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.9em;

  .icon {
    margin-right: 0.5em;
    line-height: 1;
    font-size: 1.2em;
  }
}

.container {
  position: relative;
}

.icon {
  position: absolute;
  right: 1.2em;
  top: 0.7em;
  line-height: 1;
  font-size: 1.4em;
  max-width: 1.2em;
  .success {
    color: $second-color;
  }
  .error {
    color: $forth-color;
  }
}
