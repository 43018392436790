@import "../../assets/css/var";
@import "../../assets/css/color";

.border {
  border-top: 1px solid $border-color;
  padding: 1em 0;
  &:last-child {
    border-bottom: 1px solid $border-color;
  }
}

.wrapper {
  max-width: 500px;
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid $border-color;
  li {
    display: flex;
    justify-content: space-between;
    padding: 2em 0;
    border-bottom: 1px solid $border-color;
    align-items: center;
    .label {
      max-width: 80%;
    }
  }
}
