.input-group {
  margin-bottom: 1.5em;
  .input-container {
    position: relative;
  }
}

@media only screen and (min-width: 768px) {
  .input-group {
    &.input-inline {
      display: flex;
      .input-container {
        flex: 1;
        // overflow-x: hidden;
        &:after {
          content: "";
          display: block;
          clear: both;
        }
      }
    }
  }
}
