@import "../../assets/css/color";

.list {
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid $table-border-color;
}

.item {
  display: flex;
  flex: 1;
  align-items: center;
  .photo {
    flex-basis: 70px;
  }
}
