@import "../../assets/css/color";

.list {
  list-style: none;
  padding: 0;
  margin: 1em auto 0;
  max-height: 365px;
  overflow-y: auto;
  border-top: 1px solid $border-color;
  li {
    display: flex;
    border-bottom: 1px solid $border-color;
    padding: 1em 0;
    align-items: center;
    .label {
      flex-basis: 4em;
      text-align: center;
      padding: 0 1em;
      span {
        display: inline-block;
        width: 2.5em;
        height: 2.5em;
        font-size: 0.9em;
        line-height: 2.5em;
        border-radius: 50%;
        font-weight: 500;
        color: $first-color;
        background-color: $first-color-bg;
      }
    }
    .name {
      flex: 1;
      font-weight: 500;
    }
    .duration {
      flex-basis: 14em;
      padding: 0 1em;
      .icon {
        font-size: 1.4em;
        line-height: 1;
        vertical-align: middle;
        margin-right: 0.25em;
      }
    }
    .btn {
      padding-right: 1em;
    }
  }
}
