@import "../../assets/css/color";

.page-title {
  margin-bottom: 1em;
  button {
    font-size: 1.25em;
    text-align: center;
    padding: 0;
    line-height: 1em;
    vertical-align: middle;
    margin-right: 0.5em;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    background-color: $light-color;
    &:hover {
      background-color: $dark-color;
      color: #fff;
    }
  }
}
